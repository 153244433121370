<template>
  <div>
    <div class="text-mute" style="padding: 0.5em 0">
      当前已设置的阅读模式
      <span class="text-success">（若变更仅影响当前任务中的所有文章）</span>
        <!-- <span
          class="text-info"
          style="cursor:pointer;margin-left:1em;margin-right:.5em;"
          @click="showConfigModal=true"
        >
          <Icon type="md-cog" />变更设置
        </span>
        <Poptip
          style="cursor:pointer;"
          confirm
          transfer
          title="确认要跟随默认配置吗？"
          @on-ok="configUseDefault"
        >
          <div class="text-warning">
            <Icon type="md-refresh" />跟随默认
          </div>
        </Poptip> -->
    </div>
    <template v-if="/Intensive/.test(hb.contentType)">
      <Modal v-model="showConfigModal" footer-hide>
        <div v-if="!hb.category">请先选择二级分类,再设置听读模式！</div>
        <ConfigIntensiveForm
          :baseInfoEditable="false"
          :editData="hb"
          v-else-if="showConfigModal"
          @close="showConfigModal = false"
          :noSubmit="true"
          @finish="configUseCustom"
        />
      </Modal>
      <ConfigIntensiveItem :item="hb" :baseInfoEditable="false" />
    </template>
    <template v-else>
      <Modal v-model="showConfigModal" footer-hide>
        <ConfigNormalForm
          :baseInfoEditable="false"
          :editData="hb"
          v-if="showConfigModal"
          @close="showConfigModal = false"
          :noSubmit="true"
          @finish="configUseCustom"
        />
      </Modal>
      <ConfigNormalItem :item="hb" :baseInfoEditable="false" />
    </template>
  </div>
</template>

<script>
import { getReadConfig } from "./readingModeSettings";
import ConfigNormalForm from "./ConfigNormalForm";
import ConfigNormalItem from "./ConfigNormalItem";
import ConfigIntensiveItem from "./ConfigIntensiveItem";
import ConfigIntensiveForm from "./ConfigIntensiveForm";
export default {
  props: {
    hb: {
      required: true,
    },
  },
  data() {
    return {
      showConfigModal: false,
      defaultReadModuleList: [],
      defaultIntensiveReadMouldList: [],
    };
  },
  components: {
    ConfigNormalForm,
    ConfigNormalItem,
    ConfigIntensiveForm,
    ConfigIntensiveItem,
  },
  methods: {
    configUseCustom(newData) {
      this.showConfigModal = false;
      this.hb.readModuleList = newData.readModuleList;
      this.hb.intensiveReadConfigList = newData.intensiveReadConfigList;
      this.hb.customReadModule = true;
    },
    refreshConfig() {
      if (!this.hb.category) {
        return;
      }
      getReadConfig({
        contentType: this.hb.contentType,
        category: this.hb.category,
        level: this.hb.contentLevel,
      })
        .then((res) => {
          this.defaultReadModuleList = (res && res.readModuleList) || [];
          this.defaultIntensiveReadMouldList =
            (res && res.intensiveReadConfigList) || [];
        })
        .catch(() => {
          this.defaultReadModuleList = [];
          this.defaultIntensiveReadMouldList = [];
        })
        .finally(() => {
          if (!this.hb.customReadModule) {
            this.configUseDefault();
          }
        });
    },
    configUseDefault() {
      this.hb.readModuleList = JSON.parse(
        JSON.stringify(this.defaultReadModuleList)
      );
      this.hb.intensiveReadConfigList = JSON.parse(
        JSON.stringify(this.defaultIntensiveReadMouldList)
      );
      this.hb.customReadModule = false;
    },
  },
  watch: {
    "hb.category": {
      handler() {
        this.refreshConfig();
      },
      immediate: true,
    },
  },
};
</script>

<style>
</style>